/* eslint-disable react/display-name */
import React from "react"
import { graphql } from "gatsby"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button } from "../components/Button"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { getPostObj } from "../utils/utils"
import LearnMoreCTA from "./about/LearnMoreCTA"
import { Text, Image } from "../components/Core"

import SideCTA from "../components/CTA/SideCTA"

const SingleInstruction = ({ data, pageContext, location }) => {
  const { post, language } = getPostObj(
    pageContext,
    data.allInstructionsJson.nodes[0],
    data.allSpanishInstructionsJson.nodes[0]
  )

  const translations = data.translations.nodes[0]

  return (
    <SharedStateProvider>
      <Layout className="single-instruction" language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          youtubeSchemas={pageContext.youtubeSchemasJson}
          lang={language}
        />
        <div className="columns top-section color-back">
          <div className="column is-4" />
          <div className="column">
            <Text as="h1" className="mt-0 mb-0" text={post.name} />

            <Button
              buttonText={translations.back[language]}
              goBack
              href={language === "es" ? "/es/instrucciones/" : "/instructions/"}
            />
          </div>
          <div className="column is-4" />
        </div>
        <div className="body-section joshua-tree-content instruction-content">
          {post.youtube && post.mainPhotoPublicId && (
            <div className="columns instruction-image-video">
              <div className="column is-3" />
              <div className="column">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                  overVideo
                  language={language}
                  buttonClass="contained">
                  <Image
                    publicId={post.mainPhotoPublicId}
                    useAR
                    className="theater-image"
                  />
                </TheaterVideo>
              </div>
              <div className="column is-3" />
            </div>
          )}
          <div className="columns single-instruction-content">
            <div className="column is-3" />
            <div className="column">
              <Text
                className="p-with-ul single-instructions-ul"
                text={post.body}
              />
            </div>

            {post.pdfId && (
              <>
                <div className="column is-2" />
                <div className="column is-6 here-to-help">
                  {post.pdfId && (
                    <SideCTA
                      className="single-instruction__side-cta-wrapper"
                      sideColumnIs={0}
                      subheading={
                        language === "es" ? "Versión PDF" : "PDF Version"
                      }
                      heading={
                        language === "es"
                          ? "Instrucciones de Descarga"
                          : "Download Instructions"
                      }
                      paragraph={
                        language === "es"
                          ? "Sigue las instrucciones sobre la marcha. Descargar en formato PDF."
                          : "Take the instructions on the go. Download in PDF format."
                      }
                      buttonText={language === "es" ? "Descargar" : "Download"}
                      buttonUrl={`https://res.cloudinary.com/nuvolum/image/upload/v1/${post.pdfId}.pdf`}
                      download
                    />
                  )}
                </div>
              </>
            )}
            <div className="column is-3" />
          </div>
        </div>
        {language === "en" && (
          <LearnMoreCTA colorBack post={post} language={language} />
        )}
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query ($title: String!) {
    allUniquePagesJson(filter: { template: { eq: "patient-instructions" } }) {
      nodes {
        title
      }
    }
    allInstructionsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        body
        youtube
        mainPhotoPublicId
        preOrPost
        pdfId
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    allSpanishInstructionsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        name
        metaTitle
        metaDescription
        body
        youtube
        mainPhotoPublicId
        preOrPost
        pdfId
      }
    }
    translations: allTranslationsJson {
      nodes {
        back {
          en
          es
        }
      }
    }
  }
`

export default SingleInstruction
